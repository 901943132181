import React, { useState } from 'react';
import moment from 'moment';

import { Label, Error, Icon } from 'components/lib';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import InputStyle from '../input.tailwind.js';
import Style from './dateTime.module.scss';
import {Tip} from 'components/form/tip/tip.js';

export function DateTimeInput(props) {
  // init default value
  const [date, setDate] = useState(moment(props.value || ''));
  function change(e) {
    setDate(e)
    props.onChange(props.name, moment(e).format(), true);
  }

  return (
    <div className={InputStyle.input}>
      {props.label && <Label text={props.label} required={props.required} for={props.name} />}
      {props.tip && <Tip text={props.tip} />}
      <Datetime
        value={date}
        onChange={change}
        className={Style.date}
        closeOnTab
        required={props.required}
        inputProps={{ placeholder: props.placeholder || 'Please select a date' }}
      />

      {props.valid === false && <Error message={props.errorMessage || 'Please select a date'} />}

      {props.valid === true && <Icon image="check" color="#8CC57D" className={InputStyle.successIcon} size={16} />}
    </div>
  );
}
