import {Label, Error, Icon, ClassHelper} from 'components/lib';
import Style from './input.tailwind.js';
import {useState} from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import {Tip} from '../tip/tip.js';

export function Autocomplete(props) {

  const [error, setError] = useState(props?.errorMessage || 'Please enter a value')
  const [value, setValue] = useState(props?.value || '')
  const searchOptions = {
    componentRestrictions: { country: 'US' }
  }

  function validate(text) {

    let valid = true;

    if (props.required && !text?.length) {
      valid = false;
      setError(`Please enter a value`)
    }

    if (props.min && text?.length < props.min) {
      valid = false;
      setError(`Please enter at least ${props.min} characters`)
    }
    if (props.max && text?.length > props.max) {
      valid = false;
      setError(`Please enter no more than ${props.max} characters`)
    }

    props.onChange?.(props.name, text, valid);
  }

  // style
  const textStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning
  });
  function onChange(value) {
    setValue(value)
    validate(value)
  }
  function onSelect(value) {
    setValue(value)
    validate(value)
  }
  return (
    <div className={Style.input}>
      {props.label &&
        <Label
          text={props.label}
          required={props.required}
          for={props.name}
        />}
        {props.tip &&
        <Tip
          text={props.tip}
        />}
      <PlacesAutocomplete
        value={value}
        onChange={onChange}
        onSelect={onSelect}
        searchOptions={searchOptions}
      >
        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
          <div className="relative">
            <input

              {...getInputProps({
                placeholder: props.placeholder,
                // className: ``,
                className: textStyle,
                id: props.id,
                name: props.name,
                disabled: props.disabled,
                onBlur: e => validate(e.target.value)
              })}
            />
            {suggestions?.length ?
              <div className="absolute z-10 w-full py-2 mt-2 bg-white border border-gray-300 rounded-md shadow-md z-[999]">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, idx) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                    : {backgroundColor: '#ffffff', cursor: 'pointer'};
                  return (
                    <div
                      key={idx}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      {...getSuggestionItemProps(suggestion, {
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })
                }

              </div> : <></>
            }
            {props.valid === false &&
              <Error message={error} />}

            {props.valid === true &&
              <Icon
                image='check'
                color='#8CC57D'
                className={Style.successIcon + ' top-[20px]'}
                size={16}
              />}

          </div>
        )
        }
      </PlacesAutocomplete >
    </div>)
}
