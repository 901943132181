import { ClassHelper } from 'components/lib';
import Style from './tip.tailwind.js';

export function Tip(props){

  const labelStyle = ClassHelper(Style, props);

  return(
    <div className={ labelStyle }>
      { props.text }
    </div>
  );
}