import { useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import { Card, Form, useLocation, useNavigate } from 'components/lib';

const formKeys = ['name'];

export function OrganizationDetail(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [form, setForm] = useState(null);
    const [loading, setLoading] = useState(false);
    const [organizationId, setOrganizationId] = useState('');

    const fetch = useCallback(async () => {
        if (!organizationId) return;
        setLoading(true);
        const organization = await Axios.get(`/api/mc/organization/${organizationId}`);
        if (organization.data.data && organization.data.data.length) {
            const f = {};
            Object.keys(organization.data.data[0]).forEach(key => {
                if (!formKeys.includes(key)) return;
                f[key] = {
                    label: key,
                    value: organization.data.data[0][key],
                    type: 'text'
                }
            });
            setLoading(false);
            setForm(f);
        } else {
            navigate('/404');
        }
    }, [organizationId, navigate]);

    useEffect(() => {
        let parsedPath = location?.pathname?.split('/');
        let id;
        if (Array.isArray(parsedPath) && parsedPath.length >= 3) {
          id = parsedPath[2];
        }

        if (id === 'create') id = '';
        if (id) {
            setOrganizationId(id);
            fetch(id);
        } else {
            setForm({
                name: {
                    label: 'name',
                    type: 'text',
                    value: '',
                    required: true
                },
            });
        }
    }, [fetch, location.search, location?.pathname]);

    return (
        <Card title={ `${organizationId ? 'Edit': 'Create'} Organization` } loading={ loading }>
            { form &&
                <Form
                    inputs={ form }
                    url={ organizationId ? `/api/mc/organization/${organizationId}` : '/api/mc/organization' }
                    method={ organizationId ? 'PATCH' : 'POST' }
                    buttonText={ organizationId ? 'Save Changes' : 'Create New Organization' }
                    callback={ res => {
                      navigate('/organizations');
                    }}
                />
            }
        </Card>
    );
}
