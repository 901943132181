import React from 'react';
import { Label, Error, ClassHelper } from 'components/lib';
import Style from './multiselect.tailwind.js';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {Tip} from '../../tip/tip.js';

const animatedComponents = makeAnimated();

export function Multiselect(props) {
  const options = props.options;
  const displayedValues = props.value 
    ? (props.value ?? []).map((id) => props.options.find(({value}) => value === id)) 
    : (props.default || []).map(({id, label}) => ({value: id, label}));
  const error = props.errorMessage || 'Please select an option';
  function change(val) {
    const newValue = val.map(({value}) => value);
    const valid = props.required && newValue.length > 0;
    props.onChange(props.name, newValue, valid);
    props.callback && props.callback(newValue);
  }

  const wrapperStyle = ClassHelper(Style, {
    className: props.className,
    success: props.valid === true,
    errorWrapper: props.valid === false,
    warningWrapper: props.warning,
  });

  const selectStyle = ClassHelper(Style, {
    select: true,
    error: props.valid === false,
    warning: props.warning,
  });

  return (
    <div className={Style.input}>
      <Label text={props.label} required={props.required} for={props.name} />
      <Tip text={props.tip}  />
      <div className={wrapperStyle}>
        <div className={selectStyle}>
          <Select
            classNames={{
              control: () => "py-2 px-1 border-x-0 border-y-0 pointer", borderWidth: '0!important'
            }}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles, borderWidth: '0!important'
              })
            }}
            value={displayedValues}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            onChange={e => change(e)}
            options={options}
            placeholder="Please select an option"
          />
        </div>
      </div>

      {props.valid === false && <Error message={error} className={Style.message} />}
    </div>
  );
}
