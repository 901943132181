import 'react-app-polyfill/ie9';
import { createRoot } from 'react-dom/client';
import App from './app/app';
import {Auth0Provider} from '@auth0/auth0-react';

const container = document.getElementById('root');
const root = createRoot(container); 
console.log(process.env)
root.render(
  <Auth0Provider
  useRefreshTokens={true}
  cacheLocation="localstorage"
  domain={process.env.REACT_APP_AUTH0_DOMAIN}
  clientId={process.env.REACT_APP_AUTH0_CLIENT_ID_MASTERCONTROLPANEL}
  authorizationParams={{
    redirect_uri: window.location.origin,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    scope: process.env.REACT_APP_AUTH0_APP_SCOPE
  }}
  >
    <App />
  </Auth0Provider>,);

const welcome = () => console.log('Welcome to VIPSRV 🚀')
welcome('de529c70-eb80-4dfb-9540-5075db7545bf')
