import { Label, Error, Icon, ClassHelper } from 'components/lib';
import Style from './input.tailwind.js';
import {useState} from 'react';
import {Tip} from '../tip/tip.js';

export function TextInput(props){

  const [error, setError] = useState(props.errorMessage || 'Please enter a value')

  function validate(e) {

    let value = e ? e.target.value : '';
    let valid = true;

    // input is required and value is blank
    if (props.required && !value?.length) {
      valid = false;
      setError(`Please enter a value`)
    }

    if (props.min && value?.length < props.min) {
      valid = false;
      setError(`Please enter at least ${props.min} characters`)
    }
    if (props.max && value?.length > props.max) {
      valid = false;
      setError(`Please enter no more than ${props.max} characters`)
    }
    // update the parent form
    props.onChange?.(props.name, value, valid, props.idx);

  }

  // style
  const textStyle = ClassHelper(Style, {

    textbox: true, 
    className: props.className, 
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning

  });

  return(
    <div className={ Style.input }>

      { props.label && 
        <Label
          text={ props.label }
          required={ props.required }
          for={ props.name }
        /> }
 { props.tip && 
        <Tip
          text={ props.tip }
        /> }
      { props.type === 'textarea' ?

        <textarea
          id={ props.id }
          name={ props.name }
          value={ props.value }
          className={ textStyle }
          placeholder={ props.placeholder }
          onChange={ e => props.onChange?.(props.name, e.target.value, undefined, props.idx) }
          onBlur={ e => validate(e) }
        >
        </textarea>

        :

        <input
          type='text'
          id={ props.id }
          name={ props.name }
          value={ props.value || '' }
          className={ textStyle }
          disabled={ props.disabled }
          placeholder={ props.placeholder }
          onChange={ e => props.onChange?.(props.name, e.target.value, undefined, props.idx) }
          onBlur={ e => validate(e) }
        />
      }

      { props.valid === false &&
        <Error message={ error }/> }

      { props.valid === true &&
        <Icon
          image='check'
          color='#8CC57D'
          className={ Style.successIcon }
          size={ 16 }
        />}

    </div>
  );
}
