import { useEffect } from 'react';
import axios from 'axios';
import {useContext} from 'react';
import {AuthContext} from 'components/lib';

const useAxiosInterceptor = () => {
  const authContext = useContext(AuthContext)

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.data.message === 'Account is disabled') {
          authContext.signout(null, true)
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);
};

export default useAxiosInterceptor;