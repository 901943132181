/* eslint-disable no-async-without-await/no-async-without-await */

import axios from 'axios';
import {Animate, AuthContext, Button, Card, Table, TextInput, TitleRow, ViewContext, useAPI} from 'components/lib';
import {Fragment, useEffect, useState} from 'react';
import {Pagination} from 'components/pagination/pagination';
import {useContext} from 'react';
import {useCallback} from 'react';

export function Users() {
  const orgApi = useAPI('/api/mc/organizations?disabled=0');
  const [orgs, setOrgs] = useState(null);
  let [searchText, setSearchText] = useState('')

  let [userLoading, setUserLoading] = useState(false)
  let [inviteLoading, setInviteLoading] = useState(false)

  useEffect(() => {
    setOrgs(orgApi?.data || []);
  }, [orgApi]);

  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);

  const permissionValueMap = {
    'superAdministrator': 'VIPSRV Super Administrator',
    'administrator': 'Provider Administrator',
    'provider': 'Provider',
    'concierge': 'Concierge',
  };

  const [users, setUsers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [userTotalCount, setUserTotalCount] = useState(0)
  const [inviteTotalCount, setInviteTotalCount] = useState(0)
  const [userFilters, setUserFilters] = useState({
    searchText: '',
    page: 1,
    limit: 5,
  });
  const [inviteFilters, setInviteFilters] = useState({
    searchText: '',
    page: 1,
    limit: 5,
  });
  const getUserStatus = (user) => {
    if (user.reinvited) return 'invited';
    if (user.disabled) return 'disabled';
    if (user.verified) return 'verified';
    return 'registered';
  }
  const editUser = useCallback((data, callback) => {
    let formData = {
      email: {
        label: 'Email',
        type: 'email',
        value: data.email,
        required: true
      },
      name: {
        label: 'Name',
        type: 'text',
        value: data.name,
        required: true,
      },
      last_name: {
        label: 'Last Name',
        type: 'text',
        value: data.last_name,
        required: true,
      }
    };

    if (data.permission !== 'Concierge') {
      formData = {
        ...formData,
        permission: {
          label: 'Permission',
          type: 'select',
          required: true,
          options: [
            {value: 'superAdministrator', label: 'VIPSRV Super Administrator', },
            {value: 'administrator', label: 'Provider Administrator', },
          ],
          default: data.permission,
        },
        organization_id: {
          label: 'Organization',
          type: 'select',
          required: data.permission === 'superAdministrator' ? false : true,
          disabled: data.permission === 'superAdministrator' ? true : false,
          options: orgs.map(o => {return {value: o.id, label: o.name}}),
          default: data.organization_id,
        },

      }
    }

    viewContext.modal.show({
      onFormControlChange: (input, value, form) => {
        if (input === 'permission' && value === 'superAdministrator') {
          form.organization_id.disabled = true;
          form.organization_id.required = false;
        } else if (input === 'permission' && value !== 'superAdministrator') {
          form.organization_id.disabled = false;
          form.organization_id.required = true
        }
      },
      title: 'Edit User',
      form: formData,
      buttonText: 'Save',
      url: `/api/mc/user/${data.id}`,
      method: 'PATCH'
    }, (res) => {
      viewContext.notification.show(data.name + ' was updated', 'success', true);
      // callback(res);
      setUserFilters(userFilters => ({...userFilters}))
    });
  }, [orgs]);

  const reInvite = (data) => {
    viewContext.modal.show({
      title: 'Reinvite User',
      buttonText: 'Reinvite User',
      text: `Are you sure you want to send invite ${data.email}?`,
      url: `/api/mc/user`,
      method: 'PUT',
      form: {
        email: {
          type: 'hidden',
          value: data.email,
        },
        name: {
          type: 'hidden',
          value: data.name?.length ? data.name : '',
        },
        last_name: {
          type: 'hidden',
          value: data.last_name?.length ? data.last_name : '',
        },
        permission: {
          type: 'hidden',
          value: data.permission,
        },
      },
    }, (form, res) => {
      viewContext.notification.show(`${data.email} was re-invited`, 'success', true);
      setUserFilters(userFilters => ({...userFilters}))
    });
  }
  const undeleteUser = async (data) => {
    viewContext.modal.show({
      title: 'Reactivate User',
      form: {},
      buttonText: 'Reactivate',
      text: `Are you sure you want to reactivate ${data.email}?`,
      url: `${authContext.remote_server}/api/mc/user/undelete/${data.id}`,
      method: 'POST',
      destructive: false,
    }, () => {
      viewContext.notification.show(`${data.email} was reactivated`, 'success', true);
      setUserFilters(userFilters => ({...userFilters}))
    });
  };
  const deleteUser = (data, callback) => {
    viewContext.modal.show({
      title: 'Disable User',
      form: {},
      buttonText: 'Disable User',
      text: `Are you sure you want to disable ${data.email}? This will remove them from all accounts.`,
      url: `${authContext.remote_server}/api/mc/user/${data.id}`,
      method: 'DELETE',
      destructive: true,
    }, () => {
      viewContext.notification.show(`${data.email} was disabled`, 'success', true);
      // callback();
      setUserFilters(userFilters => ({...userFilters}))
    });
  };
  function invite() {
    viewContext.modal.show({
      onFormControlChange: (input, value, form) => {
        if (input === 'permission' && value === 'superAdministrator') {
          form.organization_id.disabled = true;
          form.organization_id.required = false;
        } else if (input === 'permission' && value !== 'superAdministrator') {
          form.organization_id.disabled = false;
          form.organization_id.required = true
        }
      },
      title: 'Invite User',
      form: {
        permission: {
          label: 'Permission',
          type: 'select',
          required: true,
          options: [
            {value: 'superAdministrator', label: 'VIPSRV Super Administrator', },
            {value: 'administrator', label: 'Provider Administrator', },
          ],
          default: 'administrator',
        },
        organization_id: {
          label: 'Organization',
          type: 'select',
          required: true,
          disabled: false,
          options: orgs.map(o => {return {value: o.id, label: o.name}}),
        },
        email: {
          label: 'Email',
          type: 'text',
          required: true,
        },
        name: {
          label: 'Name',
          type: 'text',
          required: true,
        },
        last_name: {
          label: 'Last Name',
          type: 'text',
          required: true,
        },
      },
      buttonText: 'Send Invite',
      url: '/api/mc/user',
      method: 'PUT',
    }, (form) => {
      viewContext.notification.show(`${form.email.value} was invited`, 'success', true);
      setInviteFilters(inviteFilters => ({...inviteFilters}))
    });
  }
  function paginateUsers(page) {
    const newFilters = {...userFilters}
    newFilters.page = page
    setUserFilters(newFilters)
  }
  function paginateInvites(page) {
    const newFilters = {...inviteFilters}
    newFilters.page = page
    setInviteFilters(newFilters)
  }
  const search = function (_, text) {
    setSearchText(text)

    const newInviteFilters = {...inviteFilters}
    newInviteFilters.searchText = text
    setInviteFilters(newInviteFilters)

    const newUserFilters = {...userFilters}
    newUserFilters.searchText = text
    setUserFilters(newUserFilters)
  }

  useEffect(() => {
    if (!orgs) return;
    setUserLoading(true)
    const params = new URLSearchParams({
      page: userFilters.page,
      limit: userFilters.limit,
      searchText: userFilters.searchText,
    })

    axios
      .get(`/api/mc/user/v2?${params.toString()}`)
      .then(data => data.data.data)
      .then(payload => {
        let list = []
        setUserTotalCount(payload.totalCount)
        if (payload.items?.length) {
          list = payload.items.map(u => {
            let org = orgs?.find(o => o.id === u.organization_id);
            return {
              id: u.id,
              email: u.email,
              name: u.name,
              last_name: u.last_name,
              date_created: u.date_created,
              last_active: u.last_active,
              permission: u.permission,
              organization: org ? org.name : '',
              organization_id: u.organization_id,
              status: getUserStatus(u),
              actions: {
                edit: editUser,
                ...(u.disabled && !u.reinvited && {markAsEnabled: undeleteUser}),
                ...(!u.disabled && !u.reinvited && {markAsDisabled: deleteUser}),
                // only possible to send reinvite if user has been disabled and has not been send the new invite
                ...(!u.reinvited && u.disabled && !u.verified && u.permission !== 'concierge' && {
                  custom: [
                    {icon: 'mail', action: reInvite, actionName: 'Reinvite'}
                  ]
                })
                ,
              },
            };
          });
        }
        setUsers(list);
        setUserLoading(false)
      })
  }, [userFilters, orgs]);

  useEffect(() => {
    if (!orgs) return;
    setInviteLoading(true)
    const params = new URLSearchParams({
      // searchText: userFilters.searchText,
      page: inviteFilters.page,
      limit: inviteFilters.limit,
      searchText: inviteFilters.searchText,

    })

    axios
      .get(`/api/mc/invite?${params.toString()}`)
      .then(data => data.data.data)
      .then(payload => {
        let list = []
        setInviteTotalCount(payload.totalCount)
        if (payload.items?.length) {
          list = payload.items.map(u => {
            let org = orgs?.find(o => o.id === u.organization_id);
            return {
              id: u.id,
              email: u.email,
              name: u.name,
              last_name: u.last_name,
              date_created: u.date_sent,
              last_active: u.date_sent,
              permission: u.permission,
              organization: org ? org.name : '',
              organization_id: u.organization_id,
              status: 'invited',
            };
          });
        }
        setInvites(list);
        setInviteLoading(false)
      })
  }, [inviteFilters, orgs]);
  return (
    <Fragment>
      <Animate>
        <div class="flex justify-between items-end mb-2">
          <div className="w-[300px] mr-2">
            <TextInput label="Search" placeholder="Search" onChange={search} value={searchText} />
          </div>
          <div className="mr-2">
            <Button small text='Invite User' action={invite} />
          </div>
        </div>
        <div>
          <TitleRow title="Users">
          </TitleRow>
          <Card>
            <Table
              actionTitle='User'
              data={users}
              loading={userLoading}
              show={['email', 'name', 'date_created', 'last_active', 'permission', 'organization', 'status']}
              valuesMap={{permission: permissionValueMap}}
              badge={{
                col: 'status', color: 'blue', condition: [
                  {value: 'verified', color: 'green'},
                  {value: 'registered', color: 'blue'},
                  {value: 'invited', color: 'orange'},
                  {value: 'disabled', color: 'red'}
                ]
              }}
            />
          </Card>
          <Pagination page={userFilters.page} limit={userFilters.limit} total={userTotalCount} onChange={paginateUsers}></Pagination>
        </div>
        <div>
          <TitleRow title="Invites">
          </TitleRow>
          <Card>
            <Table
              actionTitle='Invite'
              data={invites}
              loading={inviteLoading}
              show={['email', 'name', 'date_created', 'last_active', 'permission', 'organization', 'status']}
              valuesMap={{permission: permissionValueMap}}
              badge={{
                col: 'status', color: 'blue', condition: [
                  {value: 'verified', color: 'green'},
                  {value: 'registered', color: 'blue'},
                  {value: 'invited', color: 'orange'},
                  {value: 'disabled', color: 'red'}
                ]
              }}
            />
          </Card>
          <Pagination page={inviteFilters.page} limit={inviteFilters.limit} total={inviteTotalCount} onChange={paginateInvites}></Pagination>
        </div>
      </Animate>
    </Fragment>
  );
}
