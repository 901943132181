// tailwind css
import '../css/output.css';

import Axios from 'axios';

// components
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoute, AuthProvider } from './auth';
import { View } from 'components/lib';
import { NotFound } from 'views/404';
import Settings from 'settings';
import {useEffect} from 'react';
import {useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
if (process.env.REACT_APP_SERVER_URL) Settings[process.env.NODE_ENV].remote_server = process.env.REACT_APP_SERVER_URL;
if (process.env.REACT_APP_WEBSITE_URL) Settings[process.env.NODE_ENV].remote_client = process.env.REACT_APP_WEBSITE_URL;

const routes = [

  ...require('routes').default,

]

export default function App(props){
  const {isAuthenticated, getAccessTokenSilently, isLoading: isAuth0Loading, logout} = useAuth0();
  const [token, setToken] = useState(null)
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  Axios.defaults.baseURL = Settings[process.env.NODE_ENV].remote_server || 'http://localhost:5000';

  useEffect(() => {
    // eslint-disable-next-line no-floating-promise/no-floating-promise
    (async () => {
      if (!isAuth0Loading && isAuthenticated) {
        let token;
        try {
         token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: process.env.REACT_APP_AUTH0_TOKEN_SCOPE,
          },
        });
      } catch (e) {
        if (e?.message  === 'Unknown or invalid refresh token.') {
          localStorage.clear();
          logout({logoutParams: {returnTo: `${window.location.origin}/signin`}})
        }
        throw e;
      }

        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        setToken(token)

        const user = JSON.parse(localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined' ? localStorage.getItem('user') : null);

        setUser(user)
        setIsLoading(false)

      } else if (!isAuth0Loading) {
        setIsLoading(false)
      }

    })();

  }, [isAuthenticated, getAccessTokenSilently, isAuth0Loading])

  if (isLoading) return <></>

  return(
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          { routes.map(route => {

            return (
              <Route 
                key={ route.path } 
                path={ route.path }
                element={ 
                  
                  route.permission ? 
                    <PrivateRoute permission={ route.permission }  user={user} token={token}>
                      <View display={ route.view } layout={ route.layout } title={ route.title }/>
                    </PrivateRoute> :
                    <View display={ route.view } layout={ route.layout } title={ route.title  }/>

                }
              />
            )
            })}

            { /* 404 */}
            <Route path='*' element={ <View display={ NotFound } layout='app' title='404 Not Found' /> }/>

        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}
