const Style = {

  base: 'inline-block mx-1 py-1 px-2.5 text-white uppercase text-xs rounded',
  red: 'bg-red-500',
  blue: 'bg-blue-500',
  green: 'bg-emerald-500',
  orange: 'bg-orange-500',
  stone: 'bg-stone-500',
  pink: 'bg-pink-500',
  gray: 'bg-pink-500',
  yellow: 'bg-yellow-500',

}

export default Style;